import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DefaultButton, Spinner } from '../../components/common'
import Modal from '../../components/common/modal'
import type { ApiResponse } from '../../types/api'
import { api } from '../../utils/api-client'
import closeModal from '../../utils/closeModal'
import { numberWithCommas } from '../../utils/number'

const Title = styled.p`
  font-size: 22px;
  font-weight: 600;
  color: var(--highlight);
  text-align: center;
  margin-top: -10px;
  margin-bottom: 12px;
`

const Conversion = styled.p`
  font-size: 18px;
  font-weight: 700;
  color: var(--txt);
  margin-bottom: 6px;
  text-align: center;
`

const Value = styled.p`
  font-size: 16px;
  color: var(--txt);
  text-align: center;
  span {
    font-weight: 700;
  }
`

const Note = styled.p`
  font-size: 14px;
  color: var(--secondary-txt);
  text-align: center;
  font-weight: 500;
  font-style: italic;
`

interface CryptoConversionProps {
  onSubmit: () => Promise<void>
  amount: string
  bankType: string
}

interface Conversion {
  number: number
  currency: string
  show_exchange_rate: number
}

const CryptoConversion = ({
  onSubmit,
  amount,
  bankType,
}: CryptoConversionProps) => {
  const { t } = useTranslation('walletManagement')
  const modal = useModal()
  const [conversion, setConversion] = useState<Conversion | null>(null)

  useEffect(() => {
    const getConversion = async () => {
      try {
        const res = await api.post<ApiResponse<Conversion>>('/crypto.php', {
          type: 'withdraw_exchange_rate',
          amount,
          bank_type_id: bankType,
        })
        const { status, info } = res.data
        if (status === 1) {
          setConversion(info)
        }
      } catch (error) {
        console.error(error)
      }
    }
    getConversion()
  }, [])

  return (
    <Modal id="crypto-conversion" zIndex={230}>
      {conversion ? (
        <>
          <Title>{t('exchange-rate')}</Title>
          <Conversion>
            1 {conversion.currency} = {conversion.show_exchange_rate} MXN
          </Conversion>
          <Value>
            {t('withdraw-amount')}: <span>{numberWithCommas(amount)} MXN</span>
          </Value>
          <Value style={{ marginBottom: 12 }}>
            {t('conversion')}:{' '}
            <span>
              {conversion.number} {conversion.currency}
            </span>
          </Value>
          <Note style={{ marginBottom: 24 }}>
            {t('crypto-surcharge')}
          </Note>
          <div className="flx-ctr" style={{ gap: 12 }}>
            <DefaultButton
              variant="blue"
              width="100%"
              onClick={() => {
                onSubmit()
                closeModal(modal)
              }}>
              {t('accept')}
            </DefaultButton>
            <DefaultButton width="100%" onClick={() => closeModal(modal)}>
              {t('no')}
            </DefaultButton>
          </div>
        </>
      ) : (
        <Spinner containerStyle={{ padding: '50px 0' }} />
      )}
    </Modal>
  )
}

export default NiceModal.create(CryptoConversion)
