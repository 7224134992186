import { useState } from 'react'

import { useBoundStore } from '../store/store'
import type { ApiResponse } from '../types/api'
import type { UserInfo } from '../types/user'
import { api } from '../utils/api-client'
import useRefreshBalance from './useRefreshBalance'

const useMemberInfo = () => {
  const { refreshBalance } = useRefreshBalance()
  const setUser = useBoundStore(state => state.user.setUser)
  const [isLoading, setIsLoading] = useState(false)

  const refreshMemberInfo = async () => {
    setIsLoading(true)
    try {
      const res = await api.post<ApiResponse<UserInfo>>('/ajax_data.php', {
        type: 'get_memberinfo_verif_request',
      })

      const { status, info } = res.data
      if (status === 1) {
        if (info.is_verified === 1) refreshBalance()
        setUser(info)
      }
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }

  return { refreshMemberInfo, isLoading }
} 

export default useMemberInfo
