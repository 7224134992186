import { styled } from '@linaria/react'

const Container = styled.div`
  border-radius: 20px;
  background-color: var(--primary);
  padding: 34px 24px;
  @media (min-width: 1200px) {
    background-color: transparent;
  }
`

const Desc = styled.p`
  font-size: 14px;
  color: var(--secondary-txt);
  text-align: center;
  font-family: 'Poppins';
  margin-top: 12px;
  @media (min-width: 1200px) {
    font-size: 16px;
  }
`

const StatusText = styled.p`
  font-size: 20px;
  text-align: center;
  font-family: 'Poppins';
  font-weight: 600;
  margin-top: 12px;
  &.success {
    color: #41bc2c;
  }
  &.in-review {
    color: #ffa800;
  }
  &.failed {
    color: #ff4040;
  }
  @media (min-width: 1200px) {
    font-size: 24px;
  }
`

interface StatusProps {
  // 1 = success, 2 = in review, 3 = failed
  status: 'success' | 'in-review' | 'failed'
  statusText: string
  desc?: string
}

const Status = ({ status, desc, statusText }: StatusProps) => {
  return (
    <Container className="v-flx-ctr">
      {status === 'success' && (
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="60" cy="60" r="60" fill="#41BC2C" fillOpacity="0.1" />
          <circle
            cx="59.9997"
            cy="59.9977"
            r="43.1579"
            fill="#41BC2C"
            fillOpacity="0.4"
          />
          <circle cx="59.9998" cy="60.0008" r="31.5789" fill="#41BC2C" />
          <path
            d="M43.1582 63.4483L52.1009 72.5796L76.8424 47.3164"
            stroke="white"
            strokeWidth="6"
            strokeLinecap="round"
          />
        </svg>
      )}
      {status === 'in-review' && (
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="60" cy="60" r="60" fill="#FFA800" fillOpacity="0.1" />
          <circle
            cx="60.0002"
            cy="59.9997"
            r="43.1579"
            fill="#FFA800"
            fillOpacity="0.4"
          />
          <circle cx="59.7367" cy="60.0008" r="31.5789" fill="#FFA800" />
          <path
            d="M66.125 71.625L69.875 75.375L77.375 67.875M62.375 41.625H53.375C51.2748 41.625 50.2247 41.625 49.4225 42.0337C48.7169 42.3932 48.1432 42.9669 47.7837 43.6725C47.375 44.4747 47.375 45.5248 47.375 47.625V69.375C47.375 71.4752 47.375 72.5254 47.7837 73.3275C48.1432 74.0331 48.7169 74.6068 49.4225 74.9662C50.2247 75.375 51.2748 75.375 53.375 75.375H59.5625M62.375 41.625L73.625 52.875M62.375 41.625V49.875C62.375 50.9251 62.375 51.4501 62.5794 51.8512C62.7592 52.204 63.0459 52.4909 63.3987 52.6706C63.7998 52.875 64.3248 52.875 65.375 52.875H73.625M73.625 52.875V61.125M54.875 67.875H59.5625M54.875 60.375H66.125M54.875 52.875H56.75"
            stroke="white"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
      {status === 'failed' && (
        <svg
          width="120"
          height="120"
          viewBox="0 0 120 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <circle cx="60" cy="60" r="60" fill="#FF4040" fillOpacity="0.1" />
          <circle
            cx="60.0002"
            cy="59.9997"
            r="43.1579"
            fill="#FF4040"
            fillOpacity="0.4"
          />
          <circle cx="59.9998" cy="60.0008" r="31.5789" fill="#FF4040" />
          <path
            d="M70.5263 49.4746L49.4736 70.5272M49.4736 49.4746L70.5263 70.5272"
            stroke="white"
            strokeWidth="5.26316"
            strokeLinecap="round"
          />
        </svg>
      )}
      <StatusText className={status}>{statusText}</StatusText>
      {desc && <Desc>{desc}</Desc>}
    </Container>
  )
}

export default Status
