import type {LinariaClassName } from '@linaria/core'
import { cx } from '@linaria/core'
import { styled } from '@linaria/react'
import type { ButtonHTMLAttributes,CSSProperties, ReactNode } from 'react'

const StyledButton = styled.button`
  background-color: var(--secondary-btn-bg);
  color: var(--secondary-btn-txt);
  line-height: 40px;
  border-radius: 6px;
  padding: 0 12px;
  font-family: 'Poppins';
  text-align: center;
  font-size: 14px;
  display: block;
  &:disabled {
    opacity: 0.5;
  }
  &.blue {
    background-color: var(--highlight);
    color: var(--primary-btn-txt);
  }
  &.green {
    background-color: var(--primary-btn-bg);
    color: var(--primary-btn-txt);
  }
  &.large {
    font-size: 16px;
    line-height: 46px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
    line-height: 46px;
    &.large {
      font-size: 18px;
      line-height: 50px;
    }
  }
`

interface DefaultButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'blue' | 'green'
  size?: 'large'
  children: ReactNode | string
  type?: 'submit' | 'button'
  onClick?: () => unknown
  className?: LinariaClassName
  width?: CSSProperties['width']
  disabled?: boolean
}

const DefaultButton = ({
  variant,
  children,
  className,
  width,
  size,
  style = {},
  ...rest
}: DefaultButtonProps) => {
  return (
    <StyledButton
      className={cx(variant, size, className)}
      style={{ width, ...style }}
      {...rest}>
      {children}
    </StyledButton>
  )
}

export default DefaultButton
