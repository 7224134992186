import { styled } from '@linaria/react'
import { useState } from 'react'

import Icon from '../icon'

const Container = styled.div`
  width: 100%;
  border-bottom: 1px solid #888;
  position: relative;
  cursor: pointer;
`

const Label = styled.div`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 14px;
  color: var(--secondary-txt);
`

const Select = styled.div`
  height: 38px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-txt);
`

const OptionsBox = styled.div`
  position: absolute;
  width: 100%;
  padding: 6px;
  box-shadow: 0px 4px 4px #b3b3b3;
  opacity: 0;
  top: 90%;
  pointer-events: none;
  max-height: 202px;
  overflow-y: auto;
  background: #fff;
  z-index: 1;
  transition: all 0.2s ease-in-out;

  ${Select}.active + & {
    pointer-events: auto;
    opacity: 1;
    top: 100%;
  }

  @media (min-width: 1200px) {
    &::-webkit-scrollbar {
      width: 7px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: #1a1a1a;
    }
  }
`

const Option = styled.div`
  height: 38px;
  padding: 0 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #aaa;

  &:hover {
    background: var(--secondary-txt-10);
  }
`

const Arrow = styled.div`
  transition: transform 0.2s ease-in-out;
  margin-left: auto;
  transform: rotate(90deg);
  ${Select}.active & {
    transform: rotate(-90deg);
  }
`

const Prepend = styled.div`
  margin-right: 10px;
`

export interface OptionType {
  label: string
  id: string
  icon?: string
  bank_type?: string
}

type WithdrawDropdownProps = {
  label?: string
  options: OptionType[]
  selected: OptionType | null
  onChange?: (selected: OptionType) => void
}

const WithdrawDropdown = ({
  label,
  options,
  selected,
  onChange,
}: WithdrawDropdownProps) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <Container
      onClick={() => {
        setIsActive(prev => !prev)
      }}>
      {label && <Label>{label}</Label>}
      <Select className={isActive ? 'active' : ''}>
        {selected?.icon && (
          <Prepend>
            <img src={selected.icon} height={15} />
          </Prepend>
        )}
        {selected?.label ?? 'Seleccione'}
        <Arrow>
          <Icon fill='#888' id="triangle-right" width="8" height="12" />
        </Arrow>
      </Select>
      <OptionsBox>
        {options.length === 0 && <Option>Vacío</Option>}
        {options.map(opt => (
          <Option
            key={opt.label}
            onClick={() => {
              onChange?.(opt)
            }}>
            {opt?.icon && (
              <Prepend>
                <img src={opt.icon} height={15} />
              </Prepend>
            )}
            {opt.label}
          </Option>
        ))}
      </OptionsBox>
    </Container>
  )
}

export default WithdrawDropdown
