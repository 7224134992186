import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import { useNavigate } from '@tanstack/router'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DefaultButton } from '../../components/common'
import Modal from '../../components/common/modal'
import Icon from '../../components/icon'
import closeModal from '../../utils/closeModal'

const Title = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: var(--highlight);
  text-align: center;
  text-transform: uppercase;
`

const SubTitle = styled.p`
  font-size: 16px;
  color: var(--highlight);
  text-align: center;
  margin-bottom: 16px;
`

const Desc = styled.p`
  font-size: 14px;
  color: var(--txt);
  text-align: center;
  margin: 16px 0;
`

const Note = styled.div`
  padding: 6px;
  border-radius: 6px;
  align-items: flex-start;
  border: 1px solid var(--secondary-txt-50);
  margin-top: 16px;
  svg {
    margin-right: 5px;
    min-width: 15px;
    width: 15px;
    height: 15px;
    color: var(--secondary-txt);
    margin-top: 2px;
  }
  p {
    font-size: 12px;
    color: var(--secondary-txt);
  }
`

interface FillUpRfcProps {
  closeWallet: () => void
}

const FillUpRfc = ({ closeWallet }: FillUpRfcProps) => {
  const { t } = useTranslation('kyc2')
  const navigate = useNavigate()
  const modal = useModal()

  return (
    <Modal id={'fill-up-rfc'} zIndex={230}>
      <Title>{t('verification-process')}</Title>
      <SubTitle>{t('first-withdraw')}</SubTitle>
      <div className="flx-ctr">
        <svg
          width="97"
          height="97"
          viewBox="0 0 97 97"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M24.0226 10.973C25.8063 11.1344 27.5942 11.242 29.3697 11.4697C32.0597 11.8173 34.2449 11.0351 35.9293 8.85819C37.7917 6.45366 39.7865 4.16501 42.2324 2.32333C46.3379 -0.764069 49.653 -0.776485 53.7295 2.2985C56.1133 4.09465 58.1123 6.29639 59.9043 8.66368C61.7253 11.0682 64.0553 11.8918 66.9854 11.4076C69.9239 10.9234 72.9036 10.9151 75.8586 11.1799C81.4375 11.6766 84.3345 14.5736 84.8311 20.1649C85.0918 23.1198 85.0877 26.0996 84.6035 29.038C84.1234 31.9723 84.947 34.2899 87.3515 36.1192C89.9382 38.085 92.351 40.2578 94.2051 42.9644C96.6261 46.503 96.6096 49.5986 94.1761 53.1289C92.3551 55.7693 90.0044 57.9131 87.4715 59.8293C85.0091 61.6916 84.1151 64.0299 84.62 67.0677C85.1208 70.0723 85.1125 73.1225 84.8146 76.1478C84.289 81.4907 81.3754 84.3712 76.0283 84.8471C73.003 85.1161 69.9611 85.112 66.9523 84.6237C64.0098 84.1436 61.7046 85.0251 59.8836 87.4172C58.0874 89.7804 56.1133 91.9986 53.7047 93.7824C49.6488 96.7829 46.3917 96.7994 42.369 93.8031C39.9728 92.0193 37.9655 89.8259 36.1777 87.4545C34.3111 84.9796 31.9397 84.1394 28.9227 84.6443C26.0546 85.1244 23.1452 85.1079 20.2564 84.8678C14.5865 84.396 11.6316 81.4411 11.1722 75.7712C10.9322 72.8162 10.9446 69.8406 11.4205 66.898C11.8799 64.0548 11.0729 61.7744 8.74284 59.9824C6.16862 58.0041 3.73926 55.8521 1.87275 53.1537C-0.635244 49.5283 -0.622828 46.4574 1.90172 42.8237C3.69374 40.2454 5.98239 38.143 8.46969 36.2765C11.0067 34.3727 11.9047 31.9889 11.3833 28.8642C10.8825 25.8637 10.8908 22.8053 11.2094 19.7841C11.764 14.5405 14.6445 11.7097 19.884 11.2006C21.2497 11.0682 22.6361 11.1799 24.0143 11.1799C24.0143 11.1096 24.0184 11.0392 24.0226 10.9689V10.973Z"
            fill="#00A6FF"
          />
          <path
            d="M42.845 49.9755C47.5464 44.7485 51.9251 39.8897 56.2872 35.0186C57.326 33.8556 58.4848 32.9245 60.074 32.6886C62.3958 32.3451 64.5809 33.3714 65.7149 35.3207C66.9399 37.4273 66.8447 39.7697 65.2307 41.6859C63.2773 44.0035 61.1914 46.2135 59.1635 48.4691C55.3394 52.7277 51.5319 56.9987 47.6913 61.2408C45.084 64.1213 41.7731 64.2372 38.9961 61.5264C36.4798 59.0722 33.9925 56.5807 31.5424 54.0603C29.1089 51.5606 29.0551 48.2125 31.3355 45.9445C33.62 43.6766 36.9144 43.78 39.4348 46.2632C40.5605 47.3765 41.5827 48.5932 42.845 49.9755Z"
            fill="#F1F1F1"
          />
        </svg>
      </div>
      <Desc>
        {t('wish-to-continue')}
      </Desc>
      <div className="flx-ctr" style={{ gap: 12 }}>
        <DefaultButton width={'100%'} onClick={() => closeModal(modal)}>
          {t('cancel')}
        </DefaultButton>
        <DefaultButton
          width={'100%'}
          variant="blue"
          onClick={() => {
            navigate({ to: '/profile/kyc' })
            closeWallet()
            closeModal(modal)
          }}>
          {t('confirm')}
        </DefaultButton>
      </div>
      <Note className="flx-str-ctr">
        <Icon id="outline-exclamation-circle" />
        <p>
          {t('laundering-law')}
        </p>
      </Note>
    </Modal>
  )
}

export default NiceModal.create(FillUpRfc)
