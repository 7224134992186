import { useModal } from '@ebay/nice-modal-react'
import { styled } from '@linaria/react'
import type { Dispatch, SetStateAction } from 'react'
import { type ChangeEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  DefaultButton,
  ScrollSelector,
  Spinner,
  ToastMessage,
  WebTitle,
} from '../../components/common'
import { type Item } from '../../components/common/scroll-selector'
import Icon from '../../components/icon'
import { TextInput } from '../../components/register'
import { useBoundStore } from '../../store/store'
import { type ApiResponse } from '../../types/api'
import type { WithdrawTabs } from '../../types/wallet-management'
import { type BankCodeObj } from '../../types/wallet-management'
import { api } from '../../utils/api-client'

const Wrapper = styled.div`
  background-color: var(--secondary);
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  @media (min-width: 1200px) {
    max-height: 600px;
    width: 500px;
    margin: 0 auto;
    min-height: unset;
    border-radius: 12px;
  }
`

const Form = styled.form`
  padding: 20px;
`

const Header = styled.div`
  font-family: 'Poppins';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  color: var(--txt);
  margin-bottom: 16px;
  @media (min-width: 1200px) {
    font-size: 18px;
  }
`

const SubmitContainer = styled.div`
  margin-top: 24px;
`

const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  color: var(--secondary-txt);
  position: relative;
`

const Arrow = styled.div<{ isActive: boolean }>`
  transition: transform 0.2s ease-in-out;
  margin-top: 10px;
  right: 15px;
  position: absolute;
  transform: ${({ isActive }) =>
    isActive ? 'rotate(-90deg)' : 'rotate(90deg)'};
`

type BankDetails = {
  realname: string
  bank_no: string
  email: string
}

interface Props {
  setActiveTab: Dispatch<SetStateAction<WithdrawTabs>>
}

const AddBank = ({ setActiveTab }: Props) => {
  const first_name = useBoundStore(state => state.user.first_name)
  const last_name = useBoundStore(state => state.user.last_name)
  const email = useBoundStore(state => state.user.email)
  const toastMessage = useModal(ToastMessage)
  const scrollSelectorModal = useModal(ScrollSelector)
  const [isLoading, setIsLoading] = useState(false)
  const [bankList, setBankList] = useState<Item[]>([])
  const [selectedBankCode, setSelectedBankCode] = useState<Item | null>(null)
  const [bankDetails, setBankDetails] = useState<BankDetails>({
    realname: first_name && last_name ? `${first_name} ${last_name}` : '',
    bank_no: '',
    email,
  })
  const { t } = useTranslation(['register', 'walletManagement'])

  const onChange: ChangeEventHandler<HTMLInputElement> = e => {
    if (e.target.name === 'bank_no' && e.target.value.length > 18) return
    setBankDetails(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }

  const fetchBankList = async () => {
    const response = await api.post<ApiResponse<BankCodeObj[]>>(
      '/payment.php',
      {
        type: 'withdraw_bank_list',
      },
    )
    const { status, info } = response.data
    if (status === 1) {
      setBankList(
        info.map(bank => ({
          label: `${bank.bank_name} - ${bank.bank_code}`,
          id: bank.bank_code,
        })),
      )
    }
  }

  const showSelectBank = () => {
    scrollSelectorModal.show({
      items: bankList,
      selected: selectedBankCode,
      onOk: (item: Item) => {
        setSelectedBankCode(item)
      },
    })
  }

  useEffect(() => {
    fetchBankList()
  }, [])

  const onAdd = async () => {
    const { realname, email, bank_no } = bankDetails

    const reqBody = {
      submit_type: 'bindcard',
      bank_type: selectedBankCode?.id,
      realname: realname,
      bank_no: bank_no,
      bank_addr: email,
      is_crypto: '0',
    }

    setIsLoading(true)
    const response = await api.post<ApiResponse<string>>('/center.php', reqBody)
    const { status, info } = response.data
    if (status === 1) {
      toastMessage.show({
        text: t('successfully-added', { ns: 'walletManagement' }),
        isValid: true,
      })
      setActiveTab('bank-list')
    } else {
      toastMessage.show({ text: info, isValid: false })
    }
    setIsLoading(false)
  }

  return (
    <Wrapper>
      <WebTitle />
      <Form
        onSubmit={e => {
          e.preventDefault()
          onAdd()
        }}>
        <Header>{t('walletManagement:bank-info')}</Header>
        <TextInput
          label={t('name')}
          name="realname"
          value={bankDetails.realname}
          onChange={onChange}
        />
        <TextInput
          label={t('email')}
          name="email"
          value={bankDetails.email}
          onChange={onChange}
        />
        <DropdownContainer>
          <TextInput
            label={t('walletManagement:bank-code')}
            name="bank_type"
            value={selectedBankCode?.label}
            onClick={showSelectBank}
            readOnly
          />
          <Arrow isActive={scrollSelectorModal.visible}>
            <Icon fill="#888" id="triangle-right" width="8" height="12" />
          </Arrow>
        </DropdownContainer>
        <TextInput
          label={'Clabe'}
          name="bank_no"
          value={bankDetails.bank_no}
          onChange={onChange}
          inputMode={'numeric'}
        />
        <SubmitContainer>
          {isLoading ? (
            <Spinner />
          ) : (
            <DefaultButton
              variant="blue"
              width="100%"
              type="submit"
              disabled={
                !selectedBankCode ||
                Object.keys(bankDetails).some(
                  key => !bankDetails[key as keyof BankDetails],
                ) ||
                bankDetails.bank_no.length !== 18
              }>
              {t('walletManagement:submit')}
            </DefaultButton>
          )}
        </SubmitContainer>
      </Form>
    </Wrapper>
  )
}

export default AddBank
