import { useMemo } from 'react'

import { useBoundStore } from '../store/store'
import type { ApiResponse } from '../types/api'
import { api } from '../utils/api-client'

interface KycInfo {
  should_kyc_status: string
  kyc1_status: string
  kyc2_status: string
  kyc_combine_status: string
}

const useKycInfo = () => {
  const kycInfo = useBoundStore(state => state.user.kycInfo)
  const setKycInfo = useBoundStore(state => state.user.setKycInfo)

  const isVerified = useMemo(() => {
    const { should_kyc_status, kyc1_status, kyc2_status, kyc_combine_status } =
      kycInfo
    if (+should_kyc_status === 2) return +kyc_combine_status
    if ([1, 3].includes(+should_kyc_status)) return +kyc1_status
    if (+should_kyc_status === 4) return +kyc2_status
    return 0
  }, [kycInfo])

  const isVerifiedBadge = useMemo(() => {
    const { kyc1_status, kyc_combine_status } = kycInfo
    if (+kyc1_status !== 0) return +kyc1_status
    if (+kyc_combine_status !== 0) return +kyc_combine_status
    return 0
  }, [kycInfo])

  const getKycInfo = async () => {
    setKycInfo({ isLoading: true })
    try {
      const res = await api.post<ApiResponse<KycInfo>>('/conduct.php', {
        type: 'which_kyc_status2',
      })
      const { status, info } = res.data
      if (status === 1) {
        setKycInfo(info)
      }
    } catch (error) {
      console.error(error)
    }
    setKycInfo({ isLoading: false })
  }

  return { kycInfo, isVerified, isVerifiedBadge, getKycInfo }
}

export default useKycInfo
